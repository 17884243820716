const ServiceData = [
    {
        id: 6,
        imgsrc: "https://yugalkunj.org/images/Canada_Summer_Camp.jpg",
        title: "Summer Camp 2024",
        Desc: "Click here to get more info Summer Camp 24 ",
        Link: "/Summer-camp-2024",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=Canada",
        Readbtn: "Read More",
        registerbtn: "Register Now",
        target:"_"
        

    },

    // {
    //     id: 1,
    //     imgsrc: "https://yugalkunj.org/images/Devotional-Retreat.jpg",
    //     title: "Devotional Retreat",
    //     Desc: "Click here to get more info Devotional Retreat  ",
    //     Link: "/CAD/Camps/Retreat",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=Canada",
    //     Readbtn: "Read More",
    //     registerbtn: "Register Now",
    //     target:""

    // },

    


    // {
    //     id: 5,
    //     imgsrc: "https://yugalkunj.org/images/Toronto_Summer_Camp.png",
    //     title: "Toronto Summer Camp",
    //     Desc: "Click here for more details on Toronto Summer Camp ",
    //     Link: "/Canada/Camps/SummerCamp",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=Canada",
    //     Readbtn: "Read More",
    //     registerbtn: "Register Now",
    //     target:""

    // },


    {
        id: 2,
        imgsrc: "https://yugalkunj.org/images/satsang.jpg",
        title: "Weekly Satsangs",
        Desc: "Click here to get more info weekly satsangs ",
        Link: "/CAD/AllEvent",
        RegistrationLink: "/CAD/AllEvent",
        Readbtn: "Read More",
        registerbtn: "Upcoming-Event",
        target:""

    },


    {
        id: 3,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/inspirational-1.jpg",
        title: "Words of Wisdom",
        Desc: "Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday. ...",
        Link: "/CAD/wordsofwisdom",
        RegistrationLink: "/CAD/wordsofwisdom",
        Readbtn: "Read More",
        registerbtn: "Read Words of Wisdom",
        target:""

    }









];

export default ServiceData; 