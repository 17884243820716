

const WordofWisdomeCard = () => {
    return (

        <>

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">


                                    <div className="lower-box">


                                        <div className="panel panel-primary">
                                            <div className="panel panel-heading">
                                                <h4>Words of Wisdom - Question & Answer Session - #135 </h4>
                                            </div>

                                            <div className="panel panel-body">
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        {/* <!--<p> <img src="images/wordofwisdom.jpg" /></p>--> */}

                                                        <p> Dear readers, it is my utmost pleasure to present a series of Q&amp;A
                                                            sessions between Shri Maharaj Ji and devotees. </p>
                                                        <p> <strong> Devotee:</strong>  Maharaj Ji, why is it that some people are truly enjoying a life of opulence, name and fame whereas others are struggling?</p>
                                                        <p>
                                                            <strong> Maharaj Ji:</strong> The truly wealthy ones are more troubled than the rest. From outside, you cannot imagine how they are feeling inside. 
                                                            They may have a fleet of cars and dozens of mansions, but they are more miserable than the rest due to their tensions, restlessness and desires. You have come to Mussoorie, and you are staying at a hotel. 
                                                            You can roam about fearlessly by yourself, even though you are a female. But Priyanka Gandhi came here last year. Few days prior to her visit, an entire caravan of police cars was going up and down the road. She was staying in a nearby hotel. But why so much concern over her safety? Think how frightened a person would be whose father (Rajiv Gandhi) and grandmother (Indira Gandhi) were both killed! She cannot feel safe despite having bodyguards because Indira Gandhi was killed by her own bodyguards. 
                                                            So, what kind of happiness will this individual be experiencing in life?

                                                            <br/>
                                                            Priyanka Gandhi is now saying that her childhood was spent in fear. Childhood is a time when a person feels free to run around, jump around, and play with friends. Children such as Priyanka cannot even go to school. They are instructed privately at home. There is so much fear all the time. So, where is the happiness? Where is the enjoyment? They are afraid and tense all the time.
                                                            <br/>
                                                            Freedom is the greatest pleasure of an individual. Suppose a girl is walking down the street. No one is walking behind her. She is walking fearlessly. But then a boy starts walking behind her. Now the girl becomes cautious. She slows down her pace; he continues to walk behind her. The boy may be just going his way without paying attention to the girl, but she has become afraid. The girl may be very wealthy, or she may enjoy a high status in society, but she cannot enjoy herself.

                                                            <br/>
                                                            The eternal sorrow of every individual is due to fear. Fear of losing one’s wealth, property, and family members. Fear is present all the time. If someone acquires true knowledge and becomes devoted to God, he will accept the material world to be temporary. He will no longer be afraid. He accepts that he may lose his wealth and property at any moment. He accepts that his loved ones may all perish at any moment. He will not be disturbed prior to nor after incurring a loss.

                                                            <br/>
                                                            When you speak about ‘enjoyment’, understand that this ‘enjoyment’ is a deception. Even the multi billionaires are unhappy from within. 


                                                           
                                                           
                                                      </p>

                                                      {/* <p> <strong> Devotee:</strong> Maharaj Ji, is an aspiring devotee sure to get human birth after death?  </p> */}

                                            
{/* 
                                                         <p> <strong> Maharaj Ji:</strong> The file of actions is being prepared right until the time of death. At present it may seem certain that he will receive a human body in the following birth. However, he may fall from the path of devotion one day and fall into bad company. He may lose faith in God and his Guru. Our present judgment about him will prove to be wrong in this case. On the other hand, another person may be an atheist at present, but he may become a devotee of God as time passes. Our present judgment about him will prove to be wrong in his case also.
                                                         <br/>
                                                         God takes a look at the entire record of actions going right up to the time of death. According to the individual’s actions, God will decide whether he will receive the human birth next time. If he is given the human birth, will he take birth in India or in some other country? Which family will he take birth in? Who will be his mother so that he grows up in a devotional atmosphere? How will he meet with a true Guru? God will take care of all this. All you need to do is to love God. 
                                                       </p> */}

{/* 
                                                        <p> <strong> Maharaj Ji:</strong>There once lived a very poor man who begged for food all day long. In the evening, he would return home and eat whatever he had collected. One day he received some parched grain. When he returned home, he hung the bowl from a nail driven into the mud wall. His plan was to eat after taking a little rest. He laid down on a broken cot and started thinking – If I sell this parched grain instead of eating it, I will earn a few coins. Then I will buy more grain and sell that also. Eventually, I will open a little shop. Then one day I will have enough to buy a goat. The goat will give birth. I will have many animals, and I will buy this-and-that and such-and-such. Then one day I will become very wealthy, and then one day I will be the king. I will get married. We will one day be blessed with a son. One day I will see my son walking toward the stables. I will scold my wife, “Look where the boy is going. Run to him and bring him back.” My wife will then say to me, “I am not a maid; I am a queen. Why not ask one of the maids to do this work?” I will say, “How dare you disobey me!” She will respond, “Why should I obey you?” I will then kick her with my leg.

                                                            <br />

                                                            Thinking this, the beggar really did kick upwards and hit the earthen begging bowl with the parched grains in it. The grain fell on his face and now he was left with nothing to eat.
                                                            <br />


                                                        </p> */}

                                                        
                                                     

                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p className="text-center"> Note: You may direct your spiritual questions to me
                                                            directly at</p>
                                                        <p className="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>






                                </div>





                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}



        </>


    )

}

export default WordofWisdomeCard;